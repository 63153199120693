var TopPanel = (function() {
  var tablePanel;

  function excelButtonInit() {
    var excelButton = $('#excel-button');
    excelButton.on('click', function(e) {
      e.preventDefault();
      var url = window.location.href + '/excel_export';
      console.log('topPanel excel-button call');
      $('#create-modal-wrapper').hide();
      App.setLoading('#create-modal-loading-wrapper');
      $('#create-modal').show();
      $.ajax({
        url: url,
        data: tablePanel.getFilter().getRequest(),
        success: function(data) {
          App.showProgressBar('#create-modal-loading-wrapper', data.total, data.id, function(data) {
            var create_modal = $('#create-modal');
            var header = $('.header');
            var appLayout = $('#app-layout');
            create_modal.fadeOut();
            $('#create-modal-wrapper').show();
            $('#create-modal-loading-wrapper').html('');
            header.show(0, function(data) {
              appLayout.css('top', '56px');
            });
            var result = $.parseJSON(data.result);
            if (result.error !== undefined) {
              alert(result.error);
            } else {
              window.open(result.filename, 'Отчет');
            }
          });
        },
        error: function(xhr, textStatus, errorThrown) {
          alert($.parseJSON(xhr.responseText));
        },
      });
    });
  }

  return {
    init: function() {
      TopPanelCustomize.init();
      var dblClick = $('.panel-top').data('dblclick');
      if (tablePanel == null) {
        var panelBottom = $('.panelBottom');
        if (panelBottom.length > 0) {
          tablePanel = new TablePanel(
            'top',
            SearchModal.getFilter(),
            '.panelBottomContent',
            '.panelBottomFiles',
            '.panelBottomHeader__id',
            dblClick
          );
          tablePanel.init(true, '.panelBottomContent', '.panelBottomFiles', '.panelBottomHeader__id');
        } else {
          tablePanel = new TablePanel(
            'top',
            SearchModal.getFilter(),
            null,
            null,
            null,
            dblClick
          );
          tablePanel.init(true, null, null, null);
        }
      } else {
        var panelBottom = $('.panelBottom');
        if (panelBottom.length > 0) {
          tablePanel.init(true, '.panelBottomContent', '.panelBottomFiles', '.panelBottomHeader__id', dblClick);
        } else {
          tablePanel.init(true, null, null, null, dblClick);
        }
      }

      var create_modal = $('#create-modal');
      var header = $('.header');
      var appLayout = $('#app-layout');

      $('#add-button').on('click', function() {
        console.log('add-button call');
        $.ajax({
          method: "GET",
          url: window.location.href + '/empty',
        }).done(function(data) {
          create_modal.html(data);
          CreateModal.init(TopPanel);
          $(document).ready(function() {
            App.initDateFields('.date__wrapper', '.date', 'datepicker-icon');
            App.initDatePickerFields('.datetimepicker__wrapper', '.datetimepicker', 'datetimepicker__icon');
            App.initSelects('.selectpicker');
            App.initAjaxSelects('.api-select');
          });
          create_modal.css("display", "flex")
                      .hide()
                      .fadeIn();
          header.hide(0, function() {
            appLayout.css('top', '0');
          });
        });
      });

      $('#copy-button').on('click', function() {
        var idNumber = tablePanel.getIdNumber();
        if (idNumber > 0) {
          console.log('copy-button call');
          $.ajax({
            method: "GET",
            url: window.location.href + '/' + idNumber,
            data: {action: "copy"}
          }).done(function(data) {
            create_modal.html(data);
            CreateModal.init(TopPanel);
            $('#copied-id').val(idNumber);
            $(document).ready(function() {
              App.initDateFields('.date__wrapper', '.date', 'datepicker-icon');
              App.initDatePickerFields('.datetimepicker__wrapper', '.datetimepicker', 'datetimepicker__icon');
              App.initSelects('.selectpicker');
              App.initAjaxSelects('.api-select');
            });
            create_modal.css("display", "flex")
                        .hide()
                        .fadeIn();
            header.hide(0, function() {
              appLayout.css('top', '0');
            });
          });
        }
      });

      $('#edit-button').on('click', function() {
        var idNumber = tablePanel.getIdNumber();
        if (idNumber > 0) {
          console.log('edit-button call');
          $.ajax({
            method: "GET",
            url: window.location.href + '/' + idNumber,
            data: {action: "edit"}
          }).done(function(data) {
            create_modal.html(data);
            var entityForm = $('.entity-form');
            entityForm.attr('action', window.location.href + '/' + idNumber);
            CreateModal.init(TopPanel);
            $(document).ready(function() {
              App.initDateFields('.date__wrapper', '.date', 'input-group-addon');
              App.initDatePickerFields('.datetimepicker__wrapper', '.datetimepicker', 'datetimepicker__icon');
              App.initSelects('.selectpicker');
              App.initAjaxSelects('.api-select');
            });
            create_modal.css("display", "flex")
                        .hide()
                        .fadeIn();
            header.hide(0, function() {
              appLayout.css('top', '0');
            });
          });
        }
      });

      $('#remove-button').on('click', function() {
        var idNumber = tablePanel.getIdNumber();
        if (idNumber > 0) {
          if (confirm('Подтвердите удаление')) {
            tablePanel.setIdNumber(0);
            console.log('remove-button call');
            $.ajax({
              method: "POST",
              url: window.location.href + '/' + idNumber,
              data: {_method: "DELETE"}
            }).done(function (data) {
              $('.panelBottomHeader__id').html('ID ');
              $('.panelBottomContent').html(''); // TODO: REMAKE
              $('#top-row-' + idNumber).remove();
            });
          }
        }
      });

      excelButtonInit();
    },
    getIdNumber: function() {
      return tablePanel.getIdNumber();
    },
    resetAjaxTableLoader: function(loadNewPage = false, firstClick = 0, overrideFilter = null) {
      tablePanel.resetAjaxTableLoader(loadNewPage, firstClick, overrideFilter);
    },
    updateRow: function(id) {
      tablePanel.updateRow(id);
    },
    getFilter: function() {
      return tablePanel.getFilter();
    },
    getArrayOfId: function() {
      return tablePanel.getArrayOfId();
    },
    pushIdToArray: function(id) {
      tablePanel.pushIdToArray(id);
    }
  }
})();
