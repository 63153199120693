function TablePanel(prefix, filter, updateSelector, fileSelector, idSelector, useDblClick = false) {
  this.prefix = prefix;
  this.idSelector = idSelector;
  this.updateSelector = updateSelector;
  this.fileSelector = fileSelector;
  this.filter = filter;
  this.useDblClick = useDblClick;
  this.ajaxTableLoader = new AjaxTableLoader('table-body', prefix, filter, this);
  this.prepare();
  this.arrayOfId = [];
}

TablePanel.prototype.init = function(loadNewPage, updateSelector, fileSelector, idSelector, useDblClick) {
  this.idSelector = idSelector;
  this.updateSelector = updateSelector;
  this.fileSelector = fileSelector;
  this.useDblClick = useDblClick;
  this.resetAjaxTableLoader(loadNewPage, 0);
  this.prepare();
  console.log('call TabelPanel.init');
};

TablePanel.prototype.prepare = function() {
  this.arrayOfId = [];
  this.setIdNumber(0);
  this.setOldSort(null);
  this.rowSetup();

  $('.' + this.prefix + '-table-body .table-item:first').click();

  var self = this;
  $('.' + this.prefix + '-table-head .header-item').unbind('click');
  $('.' + this.prefix + '-table-head .header-item').on('click', function() {
    var id = $(this).attr('id').slice(self.prefix.length + 1, -7);
    if (self.oldSort != null) {
      self.oldSort.removeClass('asc-sort');
      self.oldSort.removeClass('desc-sort');
    }
    var element = $(this);
    self.setOldSort(element);
    var sortStr = element.data('sort');
    if (sortStr.length == 0) {
      element.data('sort', id);
      element.removeClass('desc-sort');
    } else if (sortStr.charAt(0) == '-') {
      element.data('sort', '');
      element.removeClass('asc-sort');
      element.addClass('desc-sort');
    } else {
      element.data('sort', '-' + id);
      element.addClass('asc-sort');
    }
    if (sortStr.length > 0) {
      self.filter.setSort([sortStr]);
    } else {
      self.filter.setSort([]);
    }
    self.filter.setPage(0);
    self.resetAjaxTableLoader(true, 0);
  });
};

TablePanel.prototype.setOldSort = function(value) {
  this.oldSort = value;
};

TablePanel.prototype.setIdNumber = function(value) {
  this.idNumber = value;
};

TablePanel.prototype.getIdNumber = function() {
  return this.idNumber;
};

TablePanel.prototype.getOldSort = function() {
  return this.oldSort;
};

TablePanel.prototype.resetAjaxTableLoader = function(loadNewPage = false, firstClick = 0, overrideFilter = null) {
  this.ajaxTableLoader.init();
  if (loadNewPage) {
    this.ajaxTableLoader.loadNewPage(true, firstClick, overrideFilter);
  }
};

TablePanel.prototype.updateRow = function(id) {
  this.ajaxTableLoader.updateRow(id);
};

TablePanel.prototype.getFilter = function() {
  return this.filter;
}

TablePanel.prototype.clickHandler = function(id, self) {
  item = $('#' + id);
  $('#' + self.prefix + '-row-' + self.idNumber).removeClass('active-row');
  item.addClass('active-row');
  self.setIdNumber(id.substring(5 + self.prefix.length));
  var activeBottomSidebar = $('.panelBottomSidebar__btn--active');
  var route;
  if (activeBottomSidebar.length > 0) {
    route = activeBottomSidebar.attr('href');
  } else {
    route = '';
  }
  if (self.updateSelector != null) {
    App.setLoading(self.updateSelector);
    console.log('top tablePanel item click call');
    $.ajax({
      method: "GET",
      url: window.location.href + '/' + self.idNumber + route,
      data: (route.length > 0) ? {} : {action: "view"},
      success: function(data) {
        $(self.updateSelector).html(data);
        $(self.idSelector).html('ID ' + self.idNumber);
        $(self.updateSelector).perfectScrollbar('update');
        PanelBottom.reset();
        if ($('#panelBottomTable').length > 0) {
          RolesTableVue.vueInit();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        if (xhr.status == 401) {
          window.location.href = '/auth/login?redirect_uri=' + window.location.href;
        } else {
          $(self.updateSelector).html(data);
          PanelBottom.reset();
        }
      }
    });
  }
  var filePanel = $(self.fileSelector + 'Content');
  if (filePanel.is(':visible')) {
    App.setLoading(self.fileSelector + 'Content');
    console.log('top tablePanel item click files call');
    $.ajax({
      method: "GET",
      url: window.location.href + '/' + self.idNumber + '/attached_file',
    }).done(function(data, textStatus, xhr) {
      var fullFilePanel = $(self.fileSelector);
      if (xhr.status == 204) {
        filePanel.html('');
        filePanel.perfectScrollbar('update');
        if (!$(self.fileSelector + '__name').is(':visible')) {
          fullFilePanel.hide();
          PanelBottom.resizeContent();
        }
      } else {
        if (!fullFilePanel.is(':visible')) {
          fullFilePanel.show();
          PanelBottom.resizeContent();
        }
        filePanel.html(data);
        filePanel.perfectScrollbar('update');
        PanelBottom.updateLinks();
      }
    });
  }
}

TablePanel.prototype.rowSetup = function() {
  this.collectCheckedElements();
  console.log('rowSetup call');
  var tableItem = $('.' + this.prefix + '-table-body .table-item');
  tableItem.unbind('click');
  var self = this;
  tableItem.on('click', function() {
    var id = $(this).attr('id');
    self.clickHandler(id, self);
  });
  if (this.useDblClick) {
    tableItem.on('dblclick', function() {
      var idNumber = self.getIdNumber();
      var create_modal = $('#create-modal');
      var header = $('.header');
      var appLayout = $('#app-layout');
      if (idNumber > 0) {
        console.log('dblclick call');
        $.ajax({
          method: "GET",
          url: window.location.href + '/' + idNumber,
          data: {action: "edit"}
        }).done(function(data) {
          create_modal.html(data);
          var entityForm = $('.entity-form');
          entityForm.attr('action', window.location.href + '/' + idNumber);
          CreateModal.init(TopPanel);
          $(document).ready(function() {
            App.initDateFields('.date__wrapper', '.date', 'datepicker-icon');
            App.initDatePickerFields('.datetimepicker__wrapper', '.datetimepicker', 'datetimepicker__icon');
            App.initSelects('.selectpicker');
            App.initAjaxSelects('.api-select');
          });
          $('#create-modal-wrapper').perfectScrollbar('update');
          create_modal.css("display", "flex")
                      .hide()
                      .fadeIn();
          header.hide(0, function() {
            appLayout.css('top', '0');
          });
        });
      }
    });
  }
};

TablePanel.prototype.collectCheckedElements = function() {
  console.log('collectCheckedElements call');
  var self = this;
  var ajaxTable = $('.' + this.prefix + '-table-body');
  ajaxTable.unbind('click');
  var checkboxes = ajaxTable.find('input');
  checkboxes.on('click', function(event) {
    var checked = $(this).is(':checked');
    var idOfElement = $(this).parent().parent().attr('id');
    var id = idOfElement.substring(5 + self.prefix.length);
    if (checked) {
      self.arrayOfId.push(id);
    } else {
      self.arrayOfId.splice($.inArray(id, self.arrayOfId), 1);
    }
    $('#' + self.prefix + '-total-selected').html('Выбрано: ' + self.arrayOfId.length);
  });
};

TablePanel.prototype.getArrayOfId = function() {
  return this.arrayOfId;
};

TablePanel.prototype.pushIdToArray = function(id) {
  this.arrayOfId.push(...id);
};
