var App = (function() {
  var itemsToLoad;

  function initProgressBar(element, total, showText = true) {
    $(element).html(
      '<div class="progress" style="width:75%;align-self:center;height:35px">' +
        '<div class="progress-bar-success progress-bar-striped active" role="progressbar"' +
          'style="width:0%;font-size:30px;vertical-align:middle;color:white;height:35px;line-height:35px" id="loading-progress">' +
          (showText ? ('0 / ' + total) : '') +
        '</div>' +
      '</div>'
    );
  }

  function showProgressBar(element, total, jobId, successFunc, showText = true, fixedPercent = -1) {
    initProgressBar(element, total, showText);
    var token = $('#auth-user').data('token');
    var timerId = setInterval(function() {
      $.ajax({
        url: '/auth/api/v1/queued_job_progress/' + jobId,
        headers: {'Authorization': 'ESMC-Token ' + token},
        success: function(data) {
          progress = data.queued_job_progress;
          if (progress.success) {
            clearInterval(timerId);
            successFunc(data.queued_job_progress);
          } else {
            var loadingProgress = $('#loading-progress');
            if (fixedPercent > -1) {
              loadingProgress.css('width', fixedPercent + '%');
            } else {
              loadingProgress.css('width', (progress.completed / progress.total * 100) + '%');
            }
            if (showText) {
              loadingProgress.html(progress.completed + ' / ' + progress.total);
            }
          }
        }
      });
    }, 5000);
  }

  return {
    init: function() {
      SearchModal.init();
      PanelScrolls.init();
      TopPanel.init();
      SidebarMenu.init();
      CreateModal.init(TopPanel);
      PanelBottom.init();
      Header.init();
      $('#logout_link').on('click', function(e) {
        e.preventDefault();
        window.location.href = $(this).attr('href') + '?redirect_uri=' + window.location.href;
      })
      $('#auth-user').on('click', function(e) {
        e.preventDefault();
      });
      $('#user-profile').on('click', function(e) {
        e.preventDefault();
      });
    },
    setLoading: function(element) {
      $(element).html(
          '<div class="loading-wrapper">' +
            '<span class="loading-helper"></span>' +
            '<img src="/auth/img/loading.gif" width="10%" ' +
              'style="vertical-align:middle;"/>' +
          '</div>'
        );
    },
    initDateFields: function(selector, datePickerClass, iconClass) {
      $(selector).each(function() {
        var element = $(this);
        var datePicker = element.find(datePickerClass);
        var minCurDate = datePicker.data('min-cur-date');
        if (minCurDate === true) {
          var minDate = new Date();
          minDate.setHours(0, 0, 0, 0);
          datePicker.datetimepicker({
            format: 'DD.MM.YYYY',
            defaultDate: minDate,
            minDate: minDate,
          });
        } else {
          datePicker.datetimepicker({
            format: 'DD.MM.YYYY',
          });
        }
        element.on('click', function(event) {
          if ($(event.target).hasClass(iconClass)) {
            element.find(datePickerClass).focus();
          }
        });
      })
    },
    initDatePickerFields: function(selector, datePickerClass, iconClass) {
      $(selector).each(function() {
        var element = $(this);
        element.on('click', function(event) {
          if ($(event.target).hasClass(iconClass)) {
            element.find(datePickerClass).focus();
          }
        });
      })
    },
    initSelects: function(selector) {
      $(selector).selectpicker();
    },
    initAjaxSelects: function(selector) {
      var items = $(selector);
      var urlsToLoad = {};
      items.each(function() {
        var element = $(this);
        var baseUrl = element.data('list-url');
        if (baseUrl != undefined) {
          var url = baseUrl + '/auth/' + element.data('model');
          var id = element.data('list-id');
          if (!(url in urlsToLoad) && (url != undefined)) {
            urlsToLoad[url] = {};
          }
          urlsToLoad[url][id] = element;
        }
      });
      var token = $('#auth-user').data('token');
      itemsToLoad = Object.keys(urlsToLoad).length;
      for (url in urlsToLoad) {
        var ids = [];
        var values = [];
        for (id in urlsToLoad[url]) {
          ids.push(id);
          values.push(urlsToLoad[url][id].data('value'));
        }
        var idList = '[' + ids.join(',') + ']';
        var valueList = '[' + values.join(',') + ']';
        console.log('initAjaxSelects call');
        $.ajax({
          dataType: "json",
          url: url,
          data: {id: idList, value: valueList},
          headers: {'Authorization': 'ESMC-Token ' + token},
          success: function(data) {
            for (list in data) {
              var listId = list.substr(5);
              var url = this.url.split('?');
              var selectElement = urlsToLoad[url[0]][listId];
              var fieldValue = selectElement.data('value');
              var defaultValue = selectElement.data('default');
              selectElement.html($("<option></option>")
                  .attr("value", 0)
                  .text(defaultValue));
              var firstName = data[list][Object.keys(data[list])[0]];
              if (typeof firstName == 'object') {
                keysSorted = Object.keys(data[list]).sort(function(a,b) {
                  var result = 0;
                  if (data[list][a]['name'] > data[list][b]['name']) {
                    result = 1;
                  } else if (data[list][b]['name'] > data[list][a]['name']) {
                    result = -1;
                  }
                  return result;
                });
                keysSorted.forEach(function(item, i, arr) {
                  var newOption = $("<option></option>").attr("value", item);
                  if (item == 0) {
                    selectElement.find('option').first().remove();
                  }
                  for (field in data[list][item]) {
                    if (field == 'name') {
                      newOption.text(data[list][item][field]);
                    } else {
                      newOption.attr('data-' + field, data[list][item][field]);
                    }
                  }
                  selectElement.append(newOption);
                });
              } else {
                keysSorted = Object.keys(data[list]).sort(function(a,b) {
                  var result = 0;
                  if (data[list][a] > data[list][b]) {
                    result = 1;
                  } else if (data[list][b] > data[list][a]) {
                    result = -1;
                  }
                  return result;
                });
                keysSorted.forEach(function(item, i, arr) {
                  if (item == 0) {
                    selectElement.find('option').first().remove();
                  }
                  selectElement.append($("<option></option>")
                                .attr("value", item)
                                .text(data[list][item]));
                });
              }
              selectElement.val(fieldValue);
              selectElement.trigger('change');
              selectElement.attr('disabled', false);
              selectElement.removeClass('loading');
              selectElement.parent('.bootstrap-select').removeClass('loading');
              selectElement.selectpicker('refresh');
            }
            itemsToLoad--;
            if (itemsToLoad == 0) {
              var saveButton = $('.createModal__saveButton');
              console.log('initAjaxSelects enable: ' + itemsToLoad);
              saveButton.attr('disabled', false);
              saveButton.removeClass('disabled-button');
            }
          }
        });
      }
    },
    isAjaxSelectsLoading: function() {
      return itemsToLoad != 0;
    },
    showProgressBar: function(element, total, jobId, successFunc, showText = true, fixedPercent = -1) {
      return showProgressBar(element, total, jobId, successFunc, showText, fixedPercent);
    }
  }
})();
