'use strict';
var PanelBottom = (function() {
  var filter;
  var tablePanel;
  var allowedFileTypes = [
    'png',
    'jpg',
    'jpeg',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'pdf',
    'php',
    'eps',
    'ai',
    'htm',
    'html',
    'xml',
    'cms',
    'mp3',
    'mp4',
    'mpeg4'
  ];

  function saveData() {
    var entityForm = $('.panelBottomContent');
    var url = '/auth/user_role/' + TopPanel.getIdNumber() + '/access_right';
    $.ajax({
      type: "POST",
      url: url,
      data: entityForm.serialize(),
      success: function(data) {
        App.setLoading('.panelBottomContent');
        $.ajax({
          method: 'get',
          url: window.location.href + '/' + TopPanel.getIdNumber() + '/access_right',
          success: function(data) {
            var panelBottomContent = $('.panelBottomContent');
            panelBottomContent.html(data);
            $('form.panelBottomContent').attr('action', '/auth/user_role/' + TopPanel.getIdNumber() + '/access_right')
            tablePanel.init(true);
            initButtons();
            RolesTableVue.vueInit();
          },
          error: function(xhr, textStatus, errorThrown) {
            panelBottomContent.html('');
            alert($.parseJSON(xhr.responseText));
          }
        });
      },
      error: function(xhr, textStatus, errorThrown) {
        alert($.parseJSON(xhr.responseText));
      },
    });
  }

  function resizeContent() {
    var panelBottomSidebar = $('.panelBottomSidebar');
    var panelBottomContent = $('.panelBottomContent');
    if ($('.panelBottomFiles').css('display') === 'none') {
      panelBottomContent.css('width', 'calc(100% - 179px)');
      if (panelBottomSidebar.css('display') === 'none') {
        panelBottomContent.css('width','100%');
      }
    } else {
      if (panelBottomSidebar.css('display') === 'none') {
        panelBottomContent.css('width','calc(100% - 179px)');
      } else {
        panelBottomContent.css('width','calc(100% - 179px - 185px)');
      }
    }
  };

  //Function for .panelBottomContent resizing
  // This function for changing open\close SidebarMenu state
  function toggleSidebar() {
    var panelBottomMenu = $('.panelBottomSidebar__list');
    var btns = panelBottomMenu.find('.panelBottomSidebar__btn');
    var activeBtnName = $(btns[0]).text();
    var toggleName = $('.panelBottomHeader__toggleName');
    var toggle = $('.panelBottomHeader__toggle');

    $(btns[0]).addClass('panelBottomSidebar__btn--active');
    toggleName.html(activeBtnName);

    $('.panelBottomHeader__toggleBlock').on('click', function(event) {
      event.preventDefault();
      $('.panelBottomSidebar').toggleClass('panelBottomSidebar--closed');
      toggle.toggleClass('panelBottomHeader__toggle--closed');
      resizeContent();
      $('.bottom-table-body').perfectScrollbar('update');
    });
  };

  // This function for AJAX uploading data for panelBottomContent
  function sidebarBtnActive() {
    var panelBottomMenu = $('.panelBottomSidebar__list');
    var btns = panelBottomMenu.find('.panelBottomSidebar__btn');
    var toggleName = $('.panelBottomHeader__toggleName');
    var panelBottomContent = $('.panelBottomContent');

    btns.on('click', function(event) {
      event.preventDefault();
      App.setLoading('.panelBottomContent');
      $.each(btns, function() {
        $(this).removeClass('panelBottomSidebar__btn--active');
      });
      $(this).toggleClass('panelBottomSidebar__btn--active');
      var activeBtnName = $(this).text();
      toggleName.html(activeBtnName);
      var route = $(this).attr('href');
      console.log('panelBottom sidebar menu call');
      $.ajax({
        method: 'get',
        url: window.location.href + '/' + TopPanel.getIdNumber() + route,
        data: (route.length > 0) ? {} : {action: "view"},
        success: function(data) {
          panelBottomContent.html(data);
          $('form.panelBottomContent').attr('action', '/auth/user_role/' + TopPanel.getIdNumber() + '/access_right')
          if (route.length > 0) {
            var tableBody = $('.bottom-table-body');
            tableBody.each(function() {
              $(this).children('table')
                      .css('width', $(this).prev('.bottom-table-head')
                                            .prop('scrollWidth'));
              $(this).perfectScrollbar();
            });

            $('.bottom-table-head').css('width', $(this).prop('scrollWidth'));

            tableBody.on('ps-scroll-x', function () {
              $('.bottom-table-head').scrollLeft(1000);
              $(this).prev('.bottom-table-head').scrollLeft($(this).scrollLeft());
            });
            tablePanel.init(route.length > 0);
            initButtons();
            if ($('#panelBottomTable').length > 0) {
              RolesTableVue.vueInit();
            }
          } else {
            panelBottomContent.perfectScrollbar({suppressScrollX: true});
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          panelBottomContent.html('');
          alert($.parseJSON(xhr.responseText));
        }
      });
    });
  };

  //Function for uploading files to panelBottomFiles
  var uploadFile = function() {
    var templateElement = document.querySelector('template');
    var elementToClone;
    var addFile = $('#addFile');
    var addFileBtn = $('#addFileBtn');
    var panelBottomFiles = document.querySelector('.panelBottomFilesContent');


    if (templateElement != null) {
      if ('content' in templateElement) {
        elementToClone = templateElement.content.querySelector('#elementToClone');
      } else {
        elementToClone = templateElement.querySelector('#elementToClone');
      }

      //Adding new element with file and it's image to DOM when new file added
      addFile.on('change', function(event) {
        event.preventDefault();
        var element = elementToClone.cloneNode(true);
        var fileNameElem = element.querySelector('.panelBottomFiles__fileName');

        var selectedFile = addFile.get(0).files[0];
        var uploadedFileName = selectedFile.name;
        var formData = new FormData($('#add_file_form')[0]);
        var url = $('#addFileBtn').data('url');
        var itemId = TopPanel.getIdNumber();
        console.log('addFile call');
        $.ajax({
          url: url.replace('{id}', itemId),  //Server script to process data
          type: 'POST',
          data: formData,
          cache: false,
          contentType: false,
          processData: false
        }).success(function(data) {
          var fileNameArray = uploadedFileName.split('.');

          fileNameElem.textContent = fileNameArray[0];
          var newElement = panelBottomFiles.appendChild(element);
          newElement.removeAttribute('id');
          var img = newElement.querySelector('.panelBottomFiles__img');
          var viewButton = newElement.querySelector('.panelBottomFiles__btn--view');
          var viewButtonData = viewButton.dataset;
          viewButtonData.url = viewButtonData.url.replace("{file}", data);
          var downloadButton = newElement.querySelector('.panelBottomFiles__btn--download');
          var downloadButtonData = downloadButton.dataset;
          downloadButtonData.url = downloadButtonData.url.replace("{file}", data);
          var removeButton = newElement.querySelector('.panelBottomFiles__btn--remove');
          var removeButtonData = removeButton.dataset;
          removeButtonData.url = removeButtonData.url.replace("{file}", data).replace("{id}", itemId);

          _addImgIcon(img, fileNameArray);
          updateLinks();
        }).error(function(xhr, textStatus, errorThrown) {
          alert($.parseJSON(xhr.responseText));
        });
      });
    }

    //Pushing 'click' event listener from btn to hidden input[type="file"]
    addFileBtn.on('click', function(event) {
      event.preventDefault();
      addFile.click();
    });

  };

  /**
   * @param HTMLElement - DOM element type <img>
   * @param {Array.<Object>} - Array with two elements (a, b) where:
   *  a === 'string with name of loaded file
   *  b === 'string with type of loaded file'
   *  Function to add icon to loaded file
   *  */
  var _addImgIcon = function(element, fileNameArray) {
    var fileType = fileNameArray[1];

    // Test if file.type is an allowed file type.
    switch(fileType) {
      case 'doc':
      case 'docx':
        element.src = '/auth/img/' + 'iconDoc.png';
        break;

      case 'txt':
        element.src = '/auth/img/' + 'iconTxt.png';
        break;

      case 'jpg':
      case 'jpeg':
        element.src = '/auth/img/' + 'iconJpg.png';
        break;

      case 'png':
        element.src = '/auth/img/' + 'iconPng.png';
        break;

      case 'xls':
      case 'xlsx':
        element.src = '/auth/img/' + 'iconDocGreen.png';
        break;

      case 'pdf':
        element.src = '/auth/img/' + 'iconPdf.png';
        break;

      case 'eps':
        element.src = '/auth/img/' + 'iconEps.png';
        break;

      case 'ai':
        element.src = '/auth/img/' + 'iconAi.png';
        break;

      case 'htm':
      case 'html':
        element.src = '/auth/img/' + 'iconHtml.png';
        break;

      case 'php':
        element.src = '/auth/img/' + 'iconPhp.png';
        break;

      case 'cms':
        element.src = '/auth/img/' + 'iconCms.png';
        break;

      case 'xml':
        element.src = '/auth/img/' + 'iconXml.png';
        break;

      case 'mp3':
        element.src = '/auth/img/' + 'iconMp3.png';
        break;

      case 'mp4':
      case 'mpeg4':
        element.src = '/auth/img/' + 'iconMp4.png';
        break;

      default:
        element.src = '/auth/img/' + 'iconNoname.png';
    }
  };

  function resizeElementOnInput() {
    var elementTextLength = $(this).val().length;
    $(this).attr('size', elementTextLength);
  };

  function updateLinks() {
    var viewButtons = $('.panelBottomFiles__btn--view');
    viewButtons.unbind('click');
    viewButtons.on('click', function(e) {
      e.preventDefault();
      var url = $(this).data('url');
      location.href = url;
    });
    var downloadButtons = $('.panelBottomFiles__btn--download');
    downloadButtons.unbind('click');
    downloadButtons.on('click', function(e) {
      e.preventDefault();
      var url = $(this).data('url');
      location.href = url;
    });
    var removeButtons = $('.panelBottomFiles__btn--remove');
    removeButtons.unbind('click');
    removeButtons.on('click', function(e) {
      e.preventDefault();
      var element = $(this);
      var url = element.data('url');
      var itemId = TopPanel.getIdNumber();
      console.log('remove file call');
      $.ajax({
        url: url.replace('{id}', itemId),
        type: 'POST',
        data: { _method: 'DELETE' },
      }).success(function(data) {
        element.parents('.panelBottomFiles__file').remove();
      }).error(function(xhr, textStatus, errorThrown) {
        alert($.parseJSON(xhr.responseText));
      });
    });
  }

  function initButtons() {
    var fillParam = $('.panelBottom').data('fill');
    var fillClass = $('#bottom-toolbar-actions').data('class');
    $('#bottom-link-button').unbind('click');
    $('#bottom-link-button').on('click', function(e) {
      e.preventDefault();
      var url = $(this).data('route').replace('$id', TopPanel.getIdNumber());
      console.log('bottom-link-button call');
      $.ajax({
        method: "GET",
        url: url,
      }).done(function(data) {
        var create_modal = $('#create-modal');
        var appLayout = $('#app-layout');
        var header = $('.header');
        create_modal.html(data);
        $('#' + fillClass + '-' + fillParam).val(TopPanel.getIdNumber());
        var entityForm = $('.entity-form');
        var action = entityForm.attr('action').replace('$id', TopPanel.getIdNumber());
        entityForm.attr('action', action);
        CreateModal.init(PanelBottom);
        $(document).ready(function() {
          App.initDateFields('.date__wrapper', '.date', 'datepicker-icon');
          App.initDatePickerFields('.datetimepicker__wrapper', '.datetimepicker', 'datetimepicker__icon');
          App.initSelects('.selectpicker');
          App.initAjaxSelects('.api-select');
        });
        $('#create-modal-wrapper').perfectScrollbar('update');
        create_modal.css("display", "flex")
                    .hide()
                    .fadeIn();
        header.hide(0, function() {
          appLayout.css('top', '0');
        });
      });
    });
    $('#bottom-unlink-button').unbind('click');
    $('#bottom-unlink-button').on('click', function(e) {
      e.preventDefault();
      var url = $(this).data('route').replace('$id', TopPanel.getIdNumber());
      var idNumber = tablePanel.getIdNumber();
      if (idNumber > 0) {
        if (confirm('Подтвердите удаление')) {
          console.log('bottom-unlink-button call');
          $.ajax({
            method: "POST",
            url: url + '/' + idNumber,
            data: {_method: "DELETE"}
          }).done(function (data) {
            $('#bottom-row-' + idNumber).remove();
            tablePanel.setIdNumber(0);
          });
        }
      }
    });
    $('#bottom-panel-save').unbind('click');
    $('#bottom-panel-save').on('click', function(e) {
      e.preventDefault();
      saveData();
    })
    $('#bottom-panel-cancel').unbind('click');
    $('#bottom-panel-cancel').on('click', function(e) {
      e.preventDefault();
      App.setLoading('.panelBottomContent');
      $.ajax({
        method: 'get',
        url: window.location.href + '/' + TopPanel.getIdNumber() + '/access_right',
        success: function(data) {
          var panelBottomContent = $('.panelBottomContent');
          panelBottomContent.html(data);
          $('form.panelBottomContent').attr('action', '/auth/user_role/' + TopPanel.getIdNumber() + '/access_right')
          tablePanel.init(true);
          initButtons();
          RolesTableVue.vueInit();
        },
        error: function(xhr, textStatus, errorThrown) {
          panelBottomContent.html('');
          alert($.parseJSON(xhr.responseText));
        }
      });
    })
  }

  return {
    init: function() {
      if (filter == null) {
        filter = new Filter('bottom');
      } else {
        filter.reset();
      }
      if (tablePanel == null) {
        tablePanel = new TablePanel('bottom', filter, null, null, null);
      } else {
        tablePanel.init(false);
      }

      var inputText = $('.panelBottomContent__input');

      inputText.on('input', resizeElementOnInput);

      resizeContent();
      toggleSidebar();
      sidebarBtnActive();
      uploadFile();
    },
    updateLinks: function() {
      updateLinks();
    },
    resizeContent: function() {
      resizeContent();
    },
    reset: function() {
      filter.reset();
      var filterParam = $('.panelBottom').data('filter');
      filter.addFilter(filterParam, 'equ', TopPanel.getIdNumber(), 'and', false);
      var activeBottomSidebar = $('.panelBottomSidebar__btn--active');
      var route;
      if (activeBottomSidebar.length > 0) {
        route = activeBottomSidebar.attr('href');
      } else {
        route = '';
      }
      tablePanel.init(route.length > 0);
      initButtons();
    },
    resetAjaxTableLoader: function(loadNewPage = false, firstClick = 0) {
      tablePanel.resetAjaxTableLoader(loadNewPage, firstClick);
    }
  };
})();
