var CreateModalCustomize = (function() {

  function ajaxLoad(selectElement, url, data, listId, defaultValue, fieldValue) {
    selectElement.addClass('loading');
    selectElement.attr('disabled', 'disabled');
    selectElement.html($("<option></option>")
                 .text('Загрузка...'));
    var saveButton = $('#save-button');
    var token = $('#auth-user').data('token');
    saveButton.attr('disabled', 'disabled');
    saveButton.addClass('disabled-button');
    $.ajax({
      dataType: "json",
      url: url + '/' + listId + '/' + fieldValue,
      data: data,
      headers: {'Authorization': 'ESMC-Token ' + token},
      success: function(data) {
        selectElement.html($("<option></option>")
                      .attr("value", 0)
                      .text(defaultValue));
        var firstName = data['list-' + listId][Object.keys(data['list-' + listId])[0]];
        if (typeof firstName == 'object') {
          keysSorted = Object.keys(data['list-' + listId]).sort(function(a,b) {
            var result = 0;
            if (data['list-' + listId][a]['name'] > data['list-' + listId][b]['name']) {
              result = 1;
            } else if (data['list-' + listId][b]['name'] > data['list-' + listId][a]['name']) {
              result = -1;
            }
            return result;
          });
          keysSorted.forEach(function(item, i, arr) {
            var newOption = $("<option></option>").attr("value", item);
            for (field in data['list-' + listId][item]) {
              if (field == 'name') {
                newOption.text(data['list-' + listId][item][field]);
              } else {
                newOption.attr('data-' + field, data['list-' + listId][item][field]);
              }
            }
            selectElement.append(newOption);
          });
        } else {
          keysSorted = Object.keys(data['list-' + listId]).sort(function(a,b) {
            var result = 0;
            if (data['list-' + listId][a] > data['list-' + listId][b]) {
              result = 1;
            } else if (data['list-' + listId][b] > data['list-' + listId][a]) {
              result = -1;
            }
            return result;
          });
          keysSorted.forEach(function(item, i, arr) {
              selectElement.append($("<option></option>")
                            .attr("value", item)
                            .text(data['list-' + listId][item]));
          });
        }
        selectElement.val(fieldValue);
        selectElement.trigger('change');
        selectElement.attr('disabled', false);
        selectElement.removeClass('loading');
        selectElement.selectpicker('refresh');
        saveButton.attr('disabled', false);
        saveButton.removeClass('disabled-button');
      }
    });
  }

  function parentOrganizationInit() {
    var parentOrganization = $('#organization-organization_parent');
    if (parentOrganization.length > 0) {
      parentOrganization.removeClass('api-select');
      var url = parentOrganization.data('list-url') + '/orts/' + parentOrganization.data('model');
      var listId = parentOrganization.data('list-id');
      var fieldValue = parentOrganization.data('value');
      var defaultValue = parentOrganization.data('default');
      var organizationId = TopPanel.getIdNumber();
      ajaxLoad(parentOrganization, url, {exclude: '[' + organizationId + ']', exclude_field: "organizations.id"},
        listId, defaultValue, fieldValue);
    }
  }

  function seniorDispatcherInit() {
    var seniorDispatcher = $('#organization-user_senior_dispatcher');
    if (seniorDispatcher.length > 0) {
      var positionResponsible = $('#organization-position_responsible');
      seniorDispatcher.removeClass('api-select');
      var url = seniorDispatcher.data('list-url') + '/auth/' + seniorDispatcher.data('model');
      var listId = seniorDispatcher.data('list-id');
      var fieldValue = seniorDispatcher.data('value');
      var defaultValue = seniorDispatcher.data('default');
      var organizationId = TopPanel.getIdNumber();
      positionResponsible.on('change', function() {
        var positionId = $(this).val();
        ajaxLoad(seniorDispatcher, url, {organization: organizationId, position: positionId}, listId, defaultValue,
          fieldValue);
      });
    }
  }

  function organizationMechanicShiftInit() {
    var shift = $('#organization_mechanic-shift');
    if (shift.length > 0) {
      shift.removeClass('api-select');
      var url = shift.data('list-url') + '/auth/' + shift.data('model');
      var listId = shift.data('list-id');
      var fieldValue = shift.data('value');
      var defaultValue = shift.data('default');
      var organizationId = TopPanel.getIdNumber();
      ajaxLoad(shift, url, {organization: organizationId}, listId, defaultValue, fieldValue);
    }
  }

  return {
    init: function() {
      if (($('#organization-name').length > 0) || ($('#user-first_name').length > 0)) {
        $('#create-modal-wrapper').perfectScrollbar({suppressScrollX: true});
      }
      parentOrganizationInit();
      seniorDispatcherInit();
      organizationMechanicShiftInit();
    },
  }
})();
