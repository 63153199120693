var SidebarMenu = (function() {
  var oldActive;
  var menuVisible;

  function setOldActive(element) {
    oldActive = element;
  }

  function toggleMenuVisibility() {
    if (menuVisible) {
      menuVisible = false;
      $('#sidebar').animate({
        left: '-200px'
      }, 500);
      $('#content').animate({
        left: '40px',
      }, 500);
      $('#sidebar-toggler').find('>.tail').addClass('active');
      $('body').css('overflow-x', 'auto');
      $('.panelBottomContent').width('100%');
      PanelBottom.resizeContent();
    } else {
      menuVisible = true;
      $('#sidebar').animate({
        left: '40px'
      }, 500);
      $('#sidebar-toggler').find('>.tail').removeClass('active');
      $('#content').animate({
        left: '240px',
      }, 500);
      $('body').css('overflow-x', 'hidden');
      PanelBottom.resizeContent();
    }
  }

  return {
    init: function() {
      $('#sidebar').perfectScrollbar();
      oldActive = $('li.active>ul>li.active');
      menuVisible = true;

      var sideBarMenu = $('ul.sidebar-menu');

      $('#sidebar-toggler').on('click', function(e) {
        toggleMenuVisibility();
      });

      sideBarMenu.find('a').on('click', function (e) {
        var element = $(this).parent('li');
        var submenu = element.find('ul');

        if(submenu.length > 0) {
          e.preventDefault();
          var sidebar = $('#sidebar');
          sideBarMenu.children('li').not(element).each(function () {
            $(this).removeClass('active');
            $(this).find('ul').slideUp();
            sidebar.scrollTop(0);
            sidebar.perfectScrollbar('update');
          });
          if(element.hasClass('active')){
            submenu.slideUp();
            element.removeClass('active');
            sidebar.scrollTop(0);
            sidebar.perfectScrollbar('update');
          } else {
            submenu.slideDown();
            element.addClass('active');
          }
        } else {
          e.preventDefault();
          var route = $(this).attr('href');
          App.setLoading('#content');
          if (oldActive != null) {
            oldActive.removeClass('active');
          }
          setOldActive($(this).parent('li'));
          oldActive.addClass('active');
          $('#search-modal').fadeOut();
          $('#create-modal').fadeOut();
          console.log('sidebar menu call');
          $.ajax({
            method: "GET",
            url: route,
            success: function(data) {
              $('#content').html(data);
              window.history.replaceState(2, "", route);
              $('.entity-form').attr('action', route);
              $('#text-filter').val('');
              SearchModal.reset();
              PanelScrolls.init();
              TopPanel.init();
              PanelBottom.init();
            },
            error: function(xhr, textStatus, errorThrown) {
              if (xhr.status == 401) {
                window.location.href = '/auth/login?redirect_uri=' + window.location.href;
              } else {
                $('#content').html('');
                alert($.parseJSON(xhr.responseText));
              }
            }
          });
        }
      });
      var sidebarForm1 = $('#sidebar-form1');
      var sidebarForm2 = $('#sidebar-form2');
      sidebarForm2.unbind('click');
      sidebarForm1.unbind('click');
      sidebarForm1.on('click', function(e) {
        e.preventDefault();
      });
      sidebarForm2.on('click', function(e) {
        e.preventDefault();
      });
    },
    isMenuVisible: function() {
      return menuVisible;
    }
  }
})();
