var PanelScrolls = (function() {
  return {
    init: function() {
      var tableBody = $('.top-table-body');
      tableBody.each(function() {
        $(this).children('table')
                .css('width', $(this).prev('.top-table-head').prop('scrollWidth'));
        $(this).perfectScrollbar();
      });

      $('.top-table-head').css('width', $(this).prop('scrollWidth'));

      tableBody.on('ps-scroll-x', function () {
        $('.top-table-head').scrollLeft(1000);
        $(this).prev('.top-table-head').scrollLeft($(this).scrollLeft());
      });

      var panelBottom = $('.panelBottom');
      var panelTop = $('.panel-top');
      if (panelBottom.length > 0) {
        var panelBottomSidebar = $('.panelBottomSidebar');
        var panelBottomContent = $('.panelBottomContent');
        var panelBottomFiles = $('.panelBottomFilesContent');

        panelBottomSidebar.perfectScrollbar();
        panelBottomFiles.perfectScrollbar();
        panelBottomContent.perfectScrollbar({suppressScrollX: true});

        var detailContainerBody = $('.detail-container-body');
        detailContainerBody.each(function() {
          $(this).perfectScrollbar({suppressScrollX: true});
        });

        panelTop.resizable({
          handleSelector: ".splitter-horizontal",
          resizeWidth: false,
          onDrag: function (e, $el, newWidth, newHeight, opt) {
            var bodyHeight = $('.panel-container-vertical').height() - panelTop.height() - 102;
            $('.panelBottomContainerWrapper').css('height', bodyHeight + 'px');
            $('.panelBottomContainer').css('height', bodyHeight - 2 + 'px');
          },
          onDragEnd: function (e, $el, opt) {
            var bodyHeight = $('.panel-container-vertical').height() - panelTop.height() - 102;
            $('.panelBottomContainerWrapper').css('height', bodyHeight + 'px');
            var detailContainerBody = $('.panelBottomContainer');
            detailContainerBody.css('height', bodyHeight - 2 + 'px');
            detailContainerBody.perfectScrollbar('update');
          }
        });

        var bodyHeight = $('.panel-container-vertical').height() - panelTop.height() - 102;
        $('.panelBottomContainerWrapper').css('height', bodyHeight + 'px');
        detailContainerBody.css('height', bodyHeight - 2 + 'px');
        detailContainerBody.each(function() {
          $(this).perfectScrollbar({suppressScrollX: true});
        });

        $(window).on('resize', function() {
          var panelContainerVertical = $('.panel-container-vertical');
          panelContainerVertical.css('height', window.height + 'px');
          var bodyHeight = panelContainerVertical.height() - $('.panel-top').height() - 102;
          $('.panelBottomContainerWrapper').css('height', bodyHeight + 'px');
          var detailContainerBody = $('.panelBottomContainer');
          detailContainerBody.css('height', bodyHeight - 2 + 'px');
          detailContainerBody.each(function() {
            $(this).perfectScrollbar({suppressScrollX: true});
          });
        });
      } else {
        var bodyHeight = $('.panel-container-vertical').height();
        panelTop.css('height', '100%');
        panelTop.css('max-height', '100%');
        $(window).on('resize', function() {
          var panelContainerVertical = $('.panel-container-vertical');
          panelContainerVertical.css('height', window.height + 'px');
          panelTop.css('height', '100%');
          panelTop.css('max-height', '100%');
        });
      }
    }
  }
})();
