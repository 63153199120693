'use strict';
var Header = (function() {
  return {
    init: function() {
      var mainNavBtns = $('.main-nav__link');
      var mainNavHeader = $('.main-nav__header');
      var mainNavHeaderText = $('.main-nav__headerText');

      mainNavHeader.on('click', function(event) {
        $('.main-nav__popup').slideToggle('slow');
      });

      mainNavBtns.on('click', function(event) {
        $.each(mainNavBtns, function() {
          $(this).removeClass('main-nav__link--active');
        });

        $(this).toggleClass('main-nav__link--active');
        var activeBtnName = $(this).text();
        mainNavHeaderText.html(activeBtnName);
      });
    }
  };
})();
