var RolesTableVue = (function() {

  return {
    vueInit: function() {
      console.log('vue module init');
      var vm = new Vue({
        el: '#panelBottomTable',
        data: {

        },
        methods: {
          showRows: function(e) {
            var target = e.target;
            var parentRow = $(target).closest('tr');
            var parentRowData = $(parentRow).data('me');
            var thisRow = $('tr[data-parent='+parentRowData+']');
            var grandParentRow = $('tr[data-grandparent='+parentRowData+']');
            var panelBottomContent = $('.panelBottomContent');
            if (thisRow.hasClass('panelBottomTable__row--closed')) {
              thisRow.removeClass('panelBottomTable__row--closed');
              $(target).children().removeClass('fa-plus');
              $(target).children().addClass('fa-minus');
            } else {
              thisRow.addClass('panelBottomTable__row--closed');
              grandParentRow.addClass('panelBottomTable__row--closed');
              thisRow.find('.fa-minus').removeClass('fa-minus').addClass('fa-plus');
              $(target).children().removeClass('fa-minus');
              $(target).children().addClass('fa-plus');
            }
            panelBottomContent.perfectScrollbar('update');
          },
          showFlag: function(e) {
            var target = e.target;
            var thisRowData = $(target).closest('tr').data('me');
            console.log('thisRowData = ' + thisRowData);
            // Если клик на иконку флажка
            if ($(target).hasClass('panelBottomTable__icon--check')) {
              var dataValue = $(target).parent().data('value');
              console.log('dataValue = ' + dataValue);
              var childRow = $('tr[data-parent='+thisRowData+']');

              if (thisRowData < 2000000 && thisRowData > 1000000) { // Level 1
                var addToId = thisRowData - 1000000
                var inputId = 'service-' + addToId;
                console.log('inputId = ' + inputId);
                var value = parseInt($('#'+inputId+'').val());
                console.log('value = ' + value);

                if ($(target).css('display') != 'none') {
                  var value = value - dataValue;
                  var newInputValue = $('#'+inputId+'').val(value)

                  $(target).hide();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                } else {
                  var value = value + dataValue;
                  var newInputValue = $('#'+inputId+'').val(value);

                  $(target).show();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                }
              } else if (thisRowData > 2000000) {                   // Level 2
                var addToId = thisRowData - 2000000
                var inputId = 'model-' + addToId;
                console.log('inputId = ' + inputId);
                var value = parseInt($('#'+inputId+'').val());
                // Проставляем\снимаем флажки прямым потомкам при клике на втором уровне
                var descendantFlag = childRow.find('td[data-value='+dataValue+']').children('.panelBottomTable__icon--check');
                var descendantInput = childRow.find('input');

                if ($(target).css('display') != 'none') {
                  var value = value - dataValue;
                  var newInputValue = $('#'+inputId+'').val(value);
                  var descendantInputVal = descendantInput.val(value);

                  $(target).hide();
                  descendantFlag.hide();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                  console.log(descendantInput.val());
                  // Скрываем соседние флаги при клике на "Просмотр"
                  if ($(target).hasClass('panelBottomTable__icon--view')) {
                    var notViewCell = $(target).closest('tr').find('td');
                    console.log(notViewCell);
                    var notViewCellFlag = $(target).closest('tr').find('.panelBottomTable__icon--check');
                    notViewCellFlag.hide();
                    console.log('value1 = ' + value);
                    notViewCell.each(function(value) {
                      if (!$(this).hasClass('panelBottomTable__icon--view')) {
                        console.log('value2 = ' + value);
                        var notViewCellDataValue = $(this).data('value');
                        if (notViewCellDataValue != undefined) {
                          console.log('value3 = ' + value);
                          var descendantFlag = childRow.find('td[data-value='+notViewCellDataValue+']').children('.panelBottomTable__icon--check');
                          var value = value - notViewCellDataValue;
                          var newInputValue = $('#'+inputId+'').val(value);
                          var descendantInputVal = descendantInput.val(value);
                          descendantFlag.hide();
                          console.log('value4 = ' + value);
                          console.log($('#'+inputId+'').val());
                          console.log(descendantInput.val());
                        }
                      }
                    })
                  }
                  // Конец блока скрытия флагов при клике на "Просмотр"
                } else {
                  var value = value + dataValue;
                  var newInputValue = $('#'+inputId+'').val(value);
                  var descendantInputVal = descendantInput.val(value);

                  $(target).show();
                  descendantFlag.show();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                  console.log(descendantInput.val());
                }
              } else if (thisRowData < 1000000) {                   // Level 3
                var addToId = thisRowData
                var inputId = 'attribute-' + addToId;
                console.log('inputId = ' + inputId);
                var value = parseInt($('#'+inputId+'').val());

                if ($(target).css('display') != 'none') {
                  // Скрываем соседние флаги при клике на "Просмотр"
                  if ($(target).hasClass('panelBottomTable__icon--view')) {
                    console.log('value = ' + value);
                    var notViewCell = $(target).closest('tr').find('td');
                    var notViewCellFlag = $(target).closest('tr').find('.panelBottomTable__icon--check');
                    var iconsChecked = notViewCell.find('.panelBottomTable__icon--check').css('display', 'inline-block')
                    console.log(iconsChecked);
                    iconsChecked.each(function() {
                      var iconsCheckedCellDataValue = $(this).parent().data('value');
                      console.log(iconsCheckedCellDataValue);
                      if (iconsCheckedCellDataValue != undefined) {
                        notViewCellFlag.hide();
                        var value = parseInt($('#'+inputId+'').val());
                        console.log('notViewCellDataValue = ' + iconsCheckedCellDataValue);
                        console.log('value2 = ' + value);
                        var newValue = value - iconsCheckedCellDataValue;
                        var newInputValue = $('#'+inputId+'').val(newValue);
                        console.log($('#'+inputId+'').val());
                      }
                    });
                  } else {
                    var newValue = value - dataValue;
                    var newInputValue = $('#'+inputId+'').val(newValue)
                    $(target).hide();
                  }
                  // Конец блока скрытия флагов при клике на "Просмотр"
                } else {
                  var value = value + dataValue;
                  var newInputValue = $('#'+inputId+'').val(value);

                  $(target).show();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                }
              }

            } else { // Если клик на ячейку
              var flag = $(target).children('.panelBottomTable__icon--check');
              var dataValue = $(target).data('value');
              var parentRow = $(target).closest('tr');
              var parentRowData = $(parentRow).data('me');
              console.log('dataValue = ' + dataValue);
              console.log('parentRowData = ' + parentRowData);
              var childRow = $('tr[data-parent='+parentRowData+']');

              if (thisRowData < 2000000 && thisRowData > 1000000) { // Level 1
                var addToId = thisRowData - 1000000
                var inputId = 'service-' + addToId;
                console.log('inputId = ' + inputId);
                var value = parseInt($('#'+inputId+'').val());
                console.log(viewCell);

                if (flag.css('display') != 'none') {
                  var value = value - dataValue;
                  var newInputValue = $('#'+inputId+'').val(value)

                  flag.hide();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                } else {
                  var value = value + dataValue;
                  var newInputValue = $('#'+inputId+'').val(value)

                  flag.show();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                }
              } else if (thisRowData > 2000000) {                   // Level 2
                var addToId = thisRowData - 2000000
                var inputId = 'model-' + addToId;
                console.log('inputId = ' + inputId);
                var value = parseInt($('#'+inputId+'').val());
                // Проставляем флажки прямым потомкам при клике на втором уровне
                var descendantFlag = childRow.find('td[data-value='+dataValue+']').children('.panelBottomTable__icon--check');
                var descendantInput = childRow.find('input');
                //Проставляем флажки соседнему "Просмотр" и его потомкам
                var viewCell = parentRow.find('.panelBottomTable__cell--view');

                if (flag.css('display') != 'none') {
                  var value = value - dataValue;
                  var newInputValue = $('#'+inputId+'').val(value);
                  var descendantInputVal = descendantInput.val(value);

                  flag.hide();
                  descendantFlag.hide();
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                  console.log(descendantInput.val());
                  // Скрываем соседние флаги при клике на "Просмотр"
                  if ($(target).children().hasClass('panelBottomTable__icon--view')) {
                    var notViewCell = $(target).closest('tr').find('td');
                    var notViewCellFlag = $(target).closest('tr').find('.panelBottomTable__icon--check');
                    notViewCellFlag.hide();
                    notViewCell.each(function(value) {
                      if (!$(this).hasClass('panelBottomTable__icon--view')) {
                        console.log('value2 = ' + value);
                        var notViewCellDataValue = $(this).data('value');
                        if (notViewCellDataValue != undefined) {
                          console.log('value3 = ' + value);
                          var descendantFlag = childRow.find('td[data-value='+notViewCellDataValue+']').children('.panelBottomTable__icon--check');
                          var value = value - notViewCellDataValue;
                          var newInputValue = $('#'+inputId+'').val(value);
                          var descendantInputVal = descendantInput.val(value);
                          descendantFlag.hide();
                          console.log('value4 = ' + value);
                          console.log($('#'+inputId+'').val());
                          console.log(descendantInput.val());
                        }
                      }
                    });
                  }
                  // Конец блока скрытия флагов при клике на "Просмотр"
                } else {
                  var value = value + dataValue;
                  var newInputValue = $('#'+inputId+'').val(value);
                  var descendantInputVal = descendantInput.val(value);
                  console.log(descendantFlag);
                  console.log($('#'+inputId+''));
                  console.log(descendantInput);
                  console.log('value = ' + value);
                  console.log('newInputValue = ' + $('#'+inputId+'').val());
                  console.log('descendantInputVal = ' + descendantInput.val());
                  flag.show();
                  descendantFlag.show();
                  if (!$(target).hasClass('panelBottomTable__cell--view')) {
                    if (viewCell.children().css('display') == 'none') {
                      viewCell.children().show();
                      console.log('Флаг стоит в ячейке просмотр');
                      var viewCellDataValue = viewCell.data('value');
                      console.log('viewCellDataValue = ' + viewCellDataValue);
                      var value = value + viewCellDataValue;
                      var newInputValue = $('#'+inputId+'').val(value);
                      var descendantInputVal = descendantInput.val(value);
                      var descendantFlag = childRow.find('td[data-value='+viewCellDataValue+']').children('.panelBottomTable__icon--check');
                      descendantFlag.show();
                    }
                  }
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                  console.log(descendantInput.val());
                }
              } else if (thisRowData < 1000000) {                   // Level 3
                var addToId = thisRowData
                var inputId = 'attribute-' + addToId;
                var value = parseInt($('#'+inputId+'').val());
                var viewCell = parentRow.find('.panelBottomTable__cell--view');

                if (flag.css('display') != 'none') {
                  // Скрываем соседние флаги при клике на "Просмотр"
                  if ($(target).children().hasClass('panelBottomTable__icon--view')) {
                    var notViewCell = $(target).closest('tr').find('td');
                    var notViewCellFlag = $(target).closest('tr').find('.panelBottomTable__icon--check');
                    var iconsChecked = notViewCell.find('.panelBottomTable__icon--check').css('display', 'inline-block')
                    console.log(iconsChecked);
                    iconsChecked.each(function() {
                      var iconsCheckedCellDataValue = $(this).parent().data('value');
                      console.log(iconsCheckedCellDataValue);
                      if (iconsCheckedCellDataValue != undefined) {
                        notViewCellFlag.hide();
                        var value = parseInt($('#'+inputId+'').val());
                        console.log('notViewCellDataValue = ' + iconsCheckedCellDataValue);
                        console.log('value2 = ' + value);
                        var newValue = value - iconsCheckedCellDataValue;
                        var newInputValue = $('#'+inputId+'').val(newValue);
                        console.log($('#'+inputId+'').val());
                      }
                    });
                  } else {
                    var newValue = value - dataValue;
                    var newInputValue = $('#'+inputId+'').val(newValue)
                    flag.hide();
                  }
                  // Конец блока скрытия флагов при клике на "Просмотр"
                } else {
                  var value = value + dataValue;
                  var newInputValue = $('#'+inputId+'').val(value)
                  console.log('value = ' + value);
                  flag.show();
                  if (!$(target).hasClass('panelBottomTable__cell--view')) {
                    if (viewCell.children().css('display') == 'none') {
                      viewCell.children().show();
                      console.log('HeyHEYHEY');
                      var viewCellDataValue = viewCell.data('value');
                      console.log('viewCellDataValue = ' + viewCellDataValue);
                      console.log('value = ' + value);
                      var value = value + viewCellDataValue;
                      var newInputValue = $('#'+inputId+'').val(value);
                    }
                  }
                  console.log('newValue = ' + value);
                  console.log($('#'+inputId+'').val());
                }
              }
            }
          }
        }
      });
    }
  }
})();
