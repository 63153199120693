var SearchModal = (function() {
  var paramsCount;
  var casesCount;
  var paramsLeft;
  var useCases;
  var datesOnlyUseCases;
  var filter;

  function resetCounters() {
    paramsCount = 0;
    paramsLeft = 0;
    casesCount = [];
  }

  function incParamsCount() {
    paramsCount++;
    paramsLeft++;
  }

  function decParamsLeft(param) {
    casesCount[param] = -1;
    paramsLeft--;
  }

  function initCasesCount(param) {
    casesCount[param] = 0;
  }

  function incCasesCount(param) {
    casesCount[param]++;
  }

  function cleanCheckBoxes() {
    var checkboxesChecked = TopPanel.getArrayOfId();
    $.each(checkboxesChecked, function(i, val) {
      var elementSelector = 'top-row-' + val;
      $('#' + elementSelector).children('.check').children().prop('checked', false);
    });
  };

  function addCase(type, paramNum, caseNum) {
    if ((type === 'num') || (type === 'ratio')) {
      $('#' + paramNum + '-group-' + caseNum + ' .operator-div').html(
        '<select name="' + paramNum + '-operator-' + caseNum + '" id="' + paramNum +
          '-operator-' + caseNum + '" class="form-control selectpicker" data-size="5">' +
            '<option value="equ">=</option>' +
            '<option value="neq">&ne;</option>' +
            '<option value="grt">&gt;</option>' +
            '<option value="geq">&ge;</option>' +
            '<option value="les">&lt;</option>' +
            '<option value="leq">&le;</option>' +
        '</select>'
      );
      $('#' + paramNum + '-group-' + caseNum + ' .value-div').html(
        '<input name="' + paramNum + '-value-' + caseNum + '" id="' + paramNum +
          '-value-' + caseNum + '" class="form-control"/>'
      );
    } else if ((type === 'text') || (type === 'ext') || (type === 'list')) {
      $('#' + paramNum + '-group-' + caseNum + ' .operator-div').html(
        '<select name="' + paramNum + '-operator-' + caseNum + '" id="' + paramNum +
          '-operator-' + caseNum + '" class="form-control selectpicker" data-size="5">' +
            '<option value="cnt">содерж.</option>' +
            '<option value="equ">=</option>' +
            '<option value="neq">&ne;</option>' +
            '<option value="ncnt">не сод.</option>' +
            '<option value="like">шабл.</option>' +
            '<option value="nlk">не шабл.</option>' +
        '</select>'
      );
      $('#' + paramNum + '-group-' + caseNum + ' .value-div').html(
        '<input name="' + paramNum + '-value-' + caseNum + '" id="' + paramNum +
          '-value-' + caseNum + '" class="form-control"/>'
      );
    } else if (type === 'date') {
      $('#' + paramNum + '-group-' + caseNum + ' .operator-div').html(
        '<select name="' + paramNum + '-operator-' + caseNum + '" id="' + paramNum +
          '-operator-' + caseNum + '" class="form-control selectpicker" data-size="5">' +
            '<option value="equ">=</option>' +
            '<option value="neq">&ne;</option>' +
            '<option value="grt">&gt;</option>' +
            '<option value="geq">&ge;</option>' +
            '<option value="les">&lt;</option>' +
            '<option value="leq">&le;</option>' +
            '<option value="rng">период</option>' +
        '</select>'
      );
      $('#' + paramNum + '-operator-' + caseNum).on('change', function() {
        var operator = $(this).val();
        if (operator == 'rng') {
          $('#' + paramNum + '-date-' + caseNum + '-1').show();
        } else {
          $('#' + paramNum + '-date-' + caseNum + '-1').hide();
        }
      });
      $('#' + paramNum + '-group-' + caseNum + ' .value-div').html(
        '<div class="input-group date__wrapper" id="' + paramNum + '-date-' + caseNum + '-0">' +
          '<input type="text" class="form-control date" name="' + paramNum + '-value-' +
            caseNum + '-0" id="' + paramNum + '-value-' + caseNum + '-0"/>' +
          '<span class="input-group-addon datepicker-icon">' +
            '<span class="glyphicon glyphicon-calendar datepicker-icon"></span>' +
          '</span>' +
        '</div>' +
        '<div class="input-group date__wrapper" id="' + paramNum + '-date-' + caseNum + '-1" style="display:none">' +
          '<input type="text" class="form-control date" name="' + paramNum + '-value-' +
            caseNum + '-1" id="' + paramNum + '-value-' + caseNum + '-1"/>' +
          '<span class="input-group-addon datepicker-icon">' +
            '<span class="glyphicon glyphicon-calendar datepicker-icon"></span>' +
          '</span>' +
        '</div>'
      );
      App.initDateFields('#' + paramNum + '-date-' + caseNum + '-0', '.date', 'datepicker-icon');
      App.initDateFields('#' + paramNum + '-date-' + caseNum + '-1', '.date', 'datepicker-icon');
    } else if (type === 'text-search') {
      $('#' + paramNum + '-group-' + caseNum + ' .value-div').html(
        '<input name="' + paramNum + '-value-' + caseNum + '" id="' + paramNum +
          '-value-' + caseNum + '" class="form-control"/>'
      );
    } else {
      $('#' + paramNum + '-group-' + caseNum + ' .operator-div').html('');
      $('#' + paramNum + '-group-' + caseNum + ' .value-div').html('');
    }
    $(document).ready(function() {
      $('#' + paramNum + '-operator-' + caseNum).selectpicker();
    });
  }

  function onFieldChange(element) {
    var id;
    var casesId;
    var selectedType = element.find('option:selected').data('type');
    if (element.attr('id') === 'filter-new-param') {
      element.attr('id', 'param-' + paramsCount);
      element.attr('name', 'param-' + paramsCount);
      id = element.attr('id');
      casesId = id.substr(6);
      $('#new-param-group').attr('id', 'param-' + paramsCount + '-group-0');
      if (useCases && (!datesOnlyUseCases || (selectedType === 'date'))) {
        $('#filter-form').append(
          '<span class="filter-options-wrapper" id="filter-options-wrapper-' +
            paramsCount + '">' +
              '<span id="remove-param-' + paramsCount + '" class="filter-remove">' +
                'удалить фильтр' +
              '</span>' +
              '<span id="add-case-' + paramsCount + '" class="add-case">' +
                'добавить условие' +
              '</span>' +
          '</span>'
        );
      } else {
        $('#filter-form').append(
          '<span class="filter-options-wrapper" id="filter-options-wrapper-' +
            paramsCount + '">' +
              '<span id="remove-param-' + paramsCount + '" class="filter-remove">' +
                'удалить фильтр' +
              '</span>' +
          '</span>'
        );
      }
      $('#remove-param-' + paramsCount).on('click', function() {
        id = $(this).attr('id').substr(13);
        for (var i = 0; i <= casesCount[id]; i++) {
          $('#param-' + id + '-group-' + i).remove();
        }
        $('#filter-options-wrapper-' + id).remove();
        decParamsLeft(id);
      });
      if (useCases) {
        $('#add-case-' + paramsCount).on('click', function() {
          id = $(this).attr('id').substr(9);
          var type = $('#param-' + id).find('option:selected').data('type');
          $('<div class="form-group" id="param-' + id + '-group-' +
            (casesCount[id] + 1) + '">' +
              '<div class="col-sm-offset-5 col-sm-2 operator-div"></div>' +
              '<div class="col-sm-5 value-div"></div>' +
            '</div>'
          ).insertAfter('#param-' + id + '-group-' + casesCount[id]);
          incCasesCount(id);
          addCase(type, 'param-' + id, casesCount[id]);
        });
      }
      initCasesCount(paramsCount);
      incParamsCount();
      loadSelect(false);
    } else {
      id = element.attr('id');
      casesId = id.substr(6);
    }
    for (var i = 0; i <= casesCount[casesId]; i++) {
      addCase(selectedType, id, i);
    }
  }

  function loadSelect(clearHtml) {
    if (clearHtml) {
      resetCounters();
      App.setLoading('#filter-form');
    } else {
      App.setLoading('#wrapper-loading');
    }
    console.log('loadSelect call');
    $.ajax({
      method: 'GET',
      url: window.location.href + '/filter',
      success: function(data) {
        if (clearHtml) {
          $('#filter-form').html(data);
        } else {
          $('#wrapper-loading').html('');
          $('#filter-form').append(data);
        }
        $(document).ready(function() {
          var newParam = $('#filter-new-param');
          newParam.selectpicker();
          newParam.on('change', function() {
            onFieldChange($(this));
          });
        });
      }
    });
  }

  function processOneFilter(filter, param, i, j, boolType) {
    var fieldType = $('#param-' + i).find("option:selected").data('type');
    var operator = $('#param-' + i + '-operator-' + j).find("option:selected").val();
    var ext = (fieldType == 'ext');
    if (fieldType == 'date') {
      var value = $('#param-' + i + '-value-' + j + '-0').val();
      var dateValue = value.substr(6) + '-' + value.substr(3, 2) + '-' + value.substr(0, 2);
      if (operator == 'rng') {
        var value1 = $('#param-' + i + '-value-' + j + '-1').val();
        var dateValue1 = value1.substr(6) + '-' + value1.substr(3, 2) + '-' + value1.substr(0, 2);
        filter.addFilter(param, operator, [dateValue, dateValue1], boolType, ext);
      } else {
        filter.addFilter(param, operator, dateValue, boolType, ext);
      }
    } else {
      var value = $('#param-' + i + '-value-' + j).val();
      if (value.indexOf(',') > -1) {
        var values = value.split(',');
        for (var k = 0; k < values.length; k++) {
          var curOperator = operator;
          if (operator == 'cnt') {
            curOperator = 'like';
            values[k] = '*' + values[k] + '*';
          } else if (operator == 'ncnt') {
            curOperator = 'nlk';
            values[k] = '*' + values[k] + '*';
          }
          filter.addFilter(param, curOperator, values[k], 'or', ext);
        }
      } else {
        if (operator == 'cnt') {
          operator = 'like';
          value = '*' + value + '*';
        } else if (operator == 'ncnt') {
          operator = 'nlk';
          value = '*' + value + '*';
        }
        filter.addFilter(param, operator, value, boolType, ext);
      }
    }
  }

  function makeFilter() {
    filter.reset();
    for (var i = 0; i < paramsCount; i++) {
      var param = $('#param-' + i).find("option:selected").data('sql');
      if (param === 'text-search') {
        value = $('#param-' + i + '-value-0').val();
        filter.addTextSearch(fixTemplate(value));
      } else {
        if (useCases) {
          if (casesCount[i] > 0) {
            for (var j = 0; j <= casesCount[i]; j++) {
              processOneFilter(filter, param, i, j, 'or');
            }
          } else if (casesCount[i] === 0) {
            processOneFilter(filter, param, i, 0, 'and');
          }
        } else if (casesCount[i] > -1) {
          processOneFilter(filter, param, i, 0, 'and');
        }
      }
    }
  }

  return {
    init: function() {
      casesCount = [];
      if (filter == null) {
        filter = new Filter('top');
      } else {
        filter.reset();
      }
      useCases = false;
      datesOnlyUseCases = false;
      var search_modal = $('#search-modal');

      if ($("#homepage").length === 0) {
        loadSelect(true);
      }

      $('#text-filter').keypress(function(e) {
        var arrayOfId = TopPanel.getArrayOfId();
        arrayOfId.length = 0;
        var key = e.which;
        if(key == 13) {
          cleanCheckBoxes();
          var textSearch = $(this).val();
          filter.reset();
          filter.setTextSearch(textSearch);
          TopPanel.resetAjaxTableLoader(true, 0);
        }
      });

      $('#search-modal').on('keypress', function(e) {
        console.log('e.key: ' + e.key);
          if (e.key == "Enter") {
            $('#search-button').trigger('click');
          }
      });

      $('#search-modal-toggle').on('click', function() {
        if ($("#homepage").length === 0) {
          search_modal.slideToggle();
        }
      });

      search_modal.find('.close').on('click', function() {
        search_modal.fadeOut();
      });

      $('#search-button').on('click', function() {
        cleanCheckBoxes();
        makeFilter();
        search_modal.fadeOut();
        TopPanel.resetAjaxTableLoader(true, 0);
      })
    },
    reset: function() {
      loadSelect(true);
      filter.reset();
    },
    setSort: function() {
      filter.setSort();
    },
    getFilter: function() {
        return filter;
    }
  };
})();
