function Filter(prefix) {
  this.prefix = prefix;
  this.reset();
}

Filter.prototype.reset = function() {
  this.filter = [];
  this.sort = [];
  this.per_page = 50;
  this.page = 0;
  this.text_search = [];
};

Filter.prototype.setSort = function(sort) {
	this.sort = sort;
};

Filter.prototype.setPage = function(pageNum) {
	this.page = pageNum;
};

Filter.prototype.incPage = function() {
  this.page++;
};

Filter.prototype.setPageSize = function(pageSize) {
  this.per_page = pageSize;
};

Filter.prototype.setTextSearch = function(textSearch) {
  if (textSearch.length > 0) {
    this.text_search = [textSearch];
  } else {
    this.text_search = [];
  }
};

Filter.prototype.addTextSearch = function(textSearch) {
  if (textSearch.length > 0) {
    this.text_search.push(textSearch);
  }
};

Filter.prototype.addFilter = function(param, operator, value, logic, ext) {
  var where = {param: param, operator: operator, value: value, bool: logic, ext: ext};
  this.filter.push(where);
};

Filter.prototype.getRequest = function() {
  return {
    filter: JSON.stringify(this.filter),
    sort: JSON.stringify(this.sort),
    per_page: this.per_page,
    page: this.page,
    text_search: JSON.stringify(this.text_search),
    prefix: this.prefix
  };
};