var CreateModal = (function() {
  var preAjaxFunc;
  var postAjaxFunc;
  var errorFunc;

  function saveData(panel) {
    $('#create-modal-wrapper').hide();
    App.setLoading('#create-modal-loading-wrapper');
    var entityForm = $('.entity-form');
    var create_modal = $('#create-modal');
    var header = $('.header');
    var appLayout = $('#app-layout');
    var url = entityForm.attr('action');
    if (preAjaxFunc != null) {
      preAjaxFunc();
    }
    $.ajax({
      type: "POST",
      url: url,
      data: entityForm.serialize(),
      success: function(data) {
        if (postAjaxFunc != null) {
          postAjaxFunc(data);
        } else {
          panel.resetAjaxTableLoader(true, 'top-row-' + data);
          create_modal.fadeOut();
          header.show(0, function(data) {
            appLayout.css('top', '56px');
          });
          entityForm[0].reset();
          entityForm.attr('action', window.location.href);
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        if (errorFunc != null) {
          errorFunc(xhr, textStatus, errorThrown);
        } else {
          alert($.parseJSON(xhr.responseText));
          $('#create-modal-loading-wrapper').html('');
          $('#create-modal-wrapper').show();
        }
      },
    });
  }

  return {
    init: function(panel, preAjaxCustomize = null, postAjaxCustomize = null, errorCustomize = null) {
      CreateModalCustomize.init();
      preAjaxFunc = preAjaxCustomize;
      postAjaxFunc = postAjaxCustomize;
      errorFunc = errorCustomize;

      var create_modal = $('#create-modal');
      var header = $('.header');
      var appLayout = $('#app-layout');

      $('#create-modal-loading-wrapper').html('');
      $('#create-modal-wrapper').show();
//      $('#create-modal-wrapper').perfectScrollbar({suppressScrollX: true});

      $(document).keydown(function(e) {
        if (e.key == "Escape") {
          create_modal.fadeOut();
          header.show(0, function() {
            appLayout.css('top', '56px');
          });
        }
      });

      create_modal.keypress(function(e) {
        if (e.key == "Escape") {
          create_modal.fadeOut();
          header.show(0, function() {
            appLayout.css('top', '56px');
          });
        }
      });

      create_modal.find('.close').on('click', function(e) {
        e.preventDefault();
        create_modal.fadeOut();
        header.show(0, function() {
          appLayout.css('top', '56px');
        });
      });

      create_modal.find('#btnClose').on('click', function(e) {
        e.preventDefault();
        create_modal.fadeOut();
        header.show(0, function() {
          appLayout.css('top', '56px');
        });
      });

      create_modal.find('#btnCloseError').on('click', function(e) {
        e.preventDefault();
        $('#errorPopup').fadeOut();
      });

      $('#cancel-button').on('click', function() {
        $('.entity-form')[0].reset();
        create_modal.fadeOut();
        header.show(0, function() {
          appLayout.css('top', '56px');
        });
      });

      /*$('#create-modal-wrapper').keydown(function(e) {
        if (e.which == 13) {
          e.preventDefault();
          saveData(panel);
        }
      });*/

      $("#save-button").on('click', function(e) {
        e.preventDefault();
        saveData(panel);
      });
    },
    saveData: function() {
      return saveData(panel);
    }

  }
})();
