var TopPanelCustomize = (function() {

  function hideAdminRoles() {
    $('.panelBottomTable__btn--roles').hide();
  }

  return {
    init: function() {
    },
  }
})();
