function AjaxTableLoader(selector, prefix, filter, tablePanel) {
	this.filter = filter;
	this.prefix = prefix;
	this.selector = '.' + prefix + '-' + selector;
	this.tablePanel = tablePanel;
	this.init();
}

AjaxTableLoader.prototype.setLastPageReached = function(value) {
	this.lastPageReached = value;
};

AjaxTableLoader.prototype.setNewPageLoading = function(value) {
	this.newPageLoading = value;
};

AjaxTableLoader.prototype.incPage = function() {
	this.filter.incPage();
};

AjaxTableLoader.prototype.init = function() {
	this.newPageLoading = false;
	this.lastPageReached = false;
	this.filter.setPage(0);
	var self = this;
	$(this.selector).each(function() {
    $(this).on('ps-y-reach-end', function() {
      self.loadNewPage(false, false);
    });
  });
};

function successLoading(self, clearHtml, firstClick, data, xhr) {
  if ((xhr.status == 204) || (xhr.status == 401)) {
    self.setLastPageReached(true);
    if (clearHtml) {
      $(self.selector + ' .' + self.prefix + '-ajax-table').html('');
    }
    $(self.selector + ' .table-wrapper-loading').html('');
  } else {
    self.incPage();
    if (clearHtml) {
      $(self.selector + ' .' + self.prefix + '-ajax-table').html(data);
    } else {
      $(self.selector + ' .' + self.prefix + '-ajax-table').append(data);
    }
    $(self.selector + ' .table-wrapper-loading').html('');
    $(self.selector).perfectScrollbar('update');
    self.tablePanel.rowSetup();
    if (firstClick == 0) {
      $(self.selector + ' .table-item:first').click();
    } else {
      self.tablePanel.clickHandler(firstClick, self.tablePanel);
    }
  }

  var table = $(self.selector);
  var tableItems = table.find('.table-item');
  tableItems.each(function() {
    var tableItemId = $(this).attr('id').substring(5 + self.prefix.length);
    for (var j = 0; j < self.tablePanel.arrayOfId.length; j++) {
      if (tableItemId == self.tablePanel.arrayOfId[j]) {
        $(this).find('input').attr('checked', ':checked');
      }
    }
  });
  self.setNewPageLoading(false);
}

function errorLoading(self, clearHtml) {
  self.setLastPageReached(true);
  if (clearHtml) {
    $(self.selector + ' .' + self.prefix + '-ajax-table').html('');
  }
  $(self.selector + ' .table-wrapper-loading').html('');
  self.setNewPageLoading(false);
}

AjaxTableLoader.prototype.loadNewPage = function(clearHtml, firstClick, overrideFilter = null) {
  if (!this.newPageLoading && !this.lastPageReached) {
    this.setNewPageLoading(true);
    if (clearHtml) {
      App.setLoading(this.selector + ' .' + this.prefix + '-ajax-table');
    } else {
      App.setLoading(this.selector + ' .table-wrapper-loading');
    }
    var route = $(this.selector).data('route');
    var self = this;
    var data;
    var retryData = null;
    if (overrideFilter == null) {
      data = this.filter.getRequest();
    } else {
      data = overrideFilter.getRequest();
      retryData = this.filter.getRequest();
    }
    console.log('ajaxTableLoader call');
    $.ajax({
      method: 'GET',
      url: route,
      data: data,
      success: function(data, textStatus, xhr) {
        successLoading(self, clearHtml, firstClick, data, xhr)
        if (overrideFilter != null) {
          App.setLoading(this.selector + ' .table-wrapper-loading');
          $.ajax({
            method: 'GET',
            url: route,
            data: retryData,
            success: function(data, textStatus, xhr) {
              successLoading(self, false, firstClick, data, xhr)
            },
            error: function(xhr, textStatus, errorThrown) {
              errorLoading(self, clearHtml);
            }
          });
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        errorLoading(self, clearHtml);
      }
    });
  }
};

AjaxTableLoader.prototype.updateRow = function(id) {
  if (!this.newPageLoading) {
    this.setNewPageLoading(true);
    var rowSelector = '#'+this.prefix+'-row-'+id;
    App.setLoading(rowSelector);
    var route = $(this.selector).data('route');
    var self = this;
    var rowFilter = new Filter();
    var idParameter = $('#top-id-sorter').data('sort');
    rowFilter.addFilter(idParameter, 'equ', id, 'and', false);
    console.log('ajaxTableLoader updateRow call');
    $.ajax({
      method: 'GET',
      url: route,
      data: rowFilter.getRequest(),
      success: function(data, textStatus, xhr) {
        $(rowSelector).html($(data).filter(rowSelector).html());
        self.setNewPageLoading(false);
        $(rowSelector).click();
      },
      error: function(xhr, textStatus, errorThrown) {
        errorLoading(self, clearHtml);
      }
    });
  }
};
